<template>
  <v-app>
      <Cuadro/>
  </v-app>
</template>

<script>
// @ is an alias to /src
import Cuadro from '@/components/Cuadro.vue'

export default {
  name: 'CuadroView',
  components: {
    Cuadro
  }
}
</script>

